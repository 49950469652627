import React from 'react';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Logo from '../assets/images/logo.png';

const GlobalHead = ({ blogPost, imageSource, title }) => {
  const encodedTitle =
    title &&
    title
      .replace(/[^a-z0-9\s-]/gi, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase();

  return (
    <Helmet>
      <title>Frank Reynaert | Advocaat bouwrecht</title>
      {(blogPost && title && <link rel="canonical" href={'https://reynaertadvocaten.be/blog/' + encodedTitle} />) || (
        <link rel="canonical" href={'https://reynaertadvocaten.be'} />
      )}

      <meta charset="UTF-8" />
      <meta name="description" content="Juridische stabiliteit bouwen." />
      <meta name="subject" content="Frank Reynaert | Advocaat bouwrecht" />
      <meta
        name="keywords"
        content="advocaat, advocaten, onroerend goed, reynaert, recht, rechtspraak, rechtbank, juridische expertise, advies, juridisch advies"
      />
      <meta name="author" content="Frank Reynaert" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index,follow" />
      {(blogPost && title && <meta property="og:url" content={'https://reynaertadvocaten.be/blog/' + encodedTitle} />) || (
        <meta property="og:url" content={'https://reynaertadvocaten.be/'} />
      )}

      <meta property="og:type" content="website" />
      <meta property="og:title" content="Frank Reynaert | Advocaat bouwrecht" />
      {(blogPost && title && <meta property="og:description" content={title} />) || (
        <meta property="og:description" content="Juridische stabiliteit bouwen." />
      )}
      <meta property="og:locale" content="nl_BE" />
      {(blogPost && imageSource && <meta property="og:image" content={'https://reynaertadvocaten.be' + imageSource} />) || (
        <meta property="og:image" content={'https://reynaertadvocaten.be' + Logo} />
      )}
      <meta property="article:author" content="Frank Reynaert" />
      <meta name="twitter:card" content="summary" />

      {(blogPost && title && <meta property="twitter:url" content={'https://reynaertadvocaten.be/blog/' + encodedTitle} />) || (
        <meta name="twitter:url" content={'https://reynaertadvocaten.be'} />
      )}
      <meta name="twitter:title" content="Frank Reynaert | Advocaat bouwrecht" />
      {(blogPost && title && <meta name="twitter:description" content={title} />) || (
        <meta name="twitter:description" content="Juridische stabiliteit bouwen." />
      )}

      <html lang="nl" />
      {(blogPost && imageSource && <meta property="twitter:image" content={'https://reynaertadvocaten.be' + imageSource} />) || (
        <meta property="twitter:image" content={'https://reynaertadvocaten.be' + Logo} />
      )}
    </Helmet>
  );
};

export default GlobalHead;
