import { Box, Flex } from '@rebass/grid';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const BlogPreview = ({ post }) => {
  return (
    <StyledLink
      to={post.frontmatter.title
        .replace(/[^a-z0-9\s-]/gi, '')
        .trim()
        .replace(/\s+/g, '-')
        .toLowerCase()}
    >
      <BlogPreviewWrapper>
        <Flex>
          <Left src={post.frontmatter.image} />
          <Right>
            {post.frontmatter.title} ({post.frontmatter.date})
          </Right>
        </Flex>
      </BlogPreviewWrapper>
    </StyledLink>
  );
};
export default BlogPreview;

const Left = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 0 0 4px;
  margin-right: 2%;
  width: 20%;
`;

const Right = styled.div`
  width: 78%;
  padding: 40px 10px 40px 0;
`;

const BlogPreviewWrapper = styled(Box)`
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.4);
  width: 100%;
  transition: all 0.2s ease-in-out;
  margin-bottom: 30px;

  &:hover {
    box-shadow: 0 4px 30px 0 rgba(182, 182, 182, 0.45), 0 4px 12px 0 rgba(182, 182, 182, 0.23);
    cursor: pointer;
    transform: translateY(-1px);
    transition: all 0.2s ease-in-out;
  }
`;

const StyledLink = styled(Link)`
  &:visited {
    color: #333;
  }
  color: #333;
`;
